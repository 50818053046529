import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthState, useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';

import Loading from 'components/pages/Loading';
import Apollo from 'utils/apollo';

import { auth, signOut } from 'utils/firebase';
import Auth from 'utils/auth';

import { useGrowthBook, useFeatureIsOn } from '@growthbook/growthbook-react';
import Mixpanel from 'mixpanel-browser';
import { growthBookFeatureFlags } from 'utils/featureFlags';

// Redux dispatch and state selectors
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile } from 'containers/App/actions';
import { selectUser, selectLoading, selectError } from 'containers/App/selectors';

const UserAuthContext = React.createContext({
  client: {},
  user: {},
});

function UserAuthProvider({ children }) {
  const [currentUser, loading] = useAuthState(auth);
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [client, setClient] = useState({});
  const growthBook = useGrowthBook();
  const mixPanelEnabled = useFeatureIsOn(growthBookFeatureFlags.MIX_PANEL_ENABLED);
  const [enableMixPanel, setEnableMixPanel] = useState(mixPanelEnabled);

  // Redux dispatch and state selectors
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const userLoading = useSelector(selectLoading);
  const userError = useSelector(selectError);

  // Sync Mixpanel enablement state with the flag
  // useEffect(() => {
  if (mixPanelEnabled !== enableMixPanel) {
    setEnableMixPanel(mixPanelEnabled);
  }
  // }, [mixPanelEnabled, enableMixPanel]);

  const login = async (username, password, tenant) => {
    if (tenant) {
      auth.tenantId = tenant;
    }
    await signInWithEmailAndPassword(username, password);
  };

  const logout = async () => {
    await Auth.logout();
    await signOut(auth);
  };

  const createClient = async () => {
    if (!currentUser) {
      return;
    }
    const graphToken = await currentUser?.getIdToken(true);

    Apollo.removeClient();
    const c = await Apollo.createClient({
      graphToken,
    });
    setClient(c);
  };

  useEffect(() => {
    if (user?.id && currentUser && currentUser?.accessToken && !loading) {
      setIsAuthenticated(true);
      setIsAuthenticating(false);
    }
  }, [user, currentUser && currentUser?.accessToken && !loading]);

  // Authenticate and dispatch fetchUserProfile action on currentUser change
  useEffect(() => {
    if (currentUser && currentUser?.accessToken && !loading) {
      Auth?.processToken(currentUser?.accessToken).then(() => {
        setIsAuthenticated(true);
        setIsAuthenticating(false);
        dispatch(fetchUserProfile()); // Dispatching user profile fetching
        createClient(); // Create Apollo client post authentication
      });
    } else if (!loading) {
      setIsAuthenticating(false);
      setIsAuthenticated(false);
    }
  }, [currentUser, loading]);

  // Initialize Mixpanel only if the token is available and mixPanelEnabled is true
  useEffect(() => {
    const mixpanelToken = process.env.MIXPANEL_PROJECT_TOKEN || '6ec760b0b8c023c73dc8796717f59f9c';

    if (mixpanelToken) {
      Mixpanel.init(mixpanelToken, {
        ignore_dnt: true,
        debug: true,
        opt_out_tracking_by_default: !enableMixPanel,
        loaded(mixpanel) {
          growthBook.setAttributes({
            ...growthBook.getAttributes(),
            id: mixpanel.get_distinct_id(),
          });
        },
      });

      if (enableMixPanel) {
        Mixpanel.opt_in_tracking();
      } else {
        Mixpanel.opt_out_tracking();
      }
    }
  }, [enableMixPanel]);

  // Update GrowthBook attributes on user profile fetch
  useEffect(() => {
    if (isAuthenticated && user && !userLoading && !userError) {
      growthBook.setAttributes({
        ...growthBook.getAttributes(),
        user_id: user.id || user.uid || user.email,
        email: user.email,
        loggedIn: true,
      });

      const mixpanelToken = process.env.MIXPANEL_PROJECT_TOKEN || '6ec760b0b8c023c73dc8796717f59f9c';
      if (mixPanelEnabled && mixpanelToken) {
        Mixpanel.identify(user.id || user.uid || user.email);
      }
    }
  }, [isAuthenticated, user, userLoading, userError, mixPanelEnabled]);

  const value = useMemo(
    () => ({
      user: auth.currentUser,
      client,
      isAuthenticated,
      isAuthenticating,
      login,
      logout,
    }),
    [isAuthenticated, client, isAuthenticating],
  );

  if (loading) {
    return <Loading />;
  }
  return <UserAuthContext.Provider value={value}>{children}</UserAuthContext.Provider>;
}

UserAuthProvider.propTypes = {
  children: PropTypes.any,
};

export { UserAuthProvider, UserAuthContext };
